import React from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonRow,
} from "@ionic/react";
import "./HomeLanding.css"; // Import your custom CSS file
import LottiePlayer from "../LottiePlayer";

import LaptopLottie from "../../assets/lotties/phone-iso.json";

const HomeLanding: React.FC = () => {
  const scrollToContact = (sectionId: string) => {
    const section = document.getElementById(sectionId);

    if (section) {
      section.scrollIntoView({
        behavior: "smooth", // Adds smooth scrolling effect
        block: "start",
      });
    }
  };
  return (
    <IonCard className="landing-text-card">
      <IonCardContent>
        <IonGrid>
          <IonRow>
            <IonCol size-lg="6" size="12">
              <IonCardTitle mode="md" class="landing-text-title">
                Future-proof your business with cutting edge web solutions.
              </IonCardTitle>
              <IonCardSubtitle mode="md" class="landing-text-subtitle">
                Get a free quote today
              </IonCardSubtitle>
              <br />
              <IonButton
                fill="outline"
                onClick={() => scrollToContact("contact")}>
                Contact Us
              </IonButton>
            </IonCol>
            <IonCol size-lg="6" size="12">
              <LottiePlayer
                lottieJson={LaptopLottie}
                lottieHeight="100%"
                lottieWidth="100%"></LottiePlayer>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonCardContent>
    </IonCard>
  );
};

export default HomeLanding;
