import React, { useState } from "react";
import {
  IonButton,
  IonCardContent,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonIcon,
} from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import "./AboutCard.css";

const AboutCard: React.FC = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const shortContent = "Ark Web Development serves Toledo - Port Clinton and beyond, prioritizing strong customer relationships. As fellow small business owners, we value personal connections and are available to support you beyond normal business hours...";
  const fullContent = "Ark Web Development serves Toledo - Port Clinton and beyond, prioritizing strong customer relationships. As fellow small business owners, we value personal connections and are available to support you beyond normal business hours. Our passionate team of entrepreneurs is dedicated to fostering the growth of your start-up or small business. With cutting-edge technology and progressive web apps, we create fast, affordable, and mobile-optimized websites that attract loyal customers and effectively convey your brand. Let us help you reach your engagement and conversion rate goals with our user-friendly solutions. Partner with Ark Web Development for exceptional web development and unlock your business's online potential.";

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="about-section">
      <h2 className="section-heading">About ARK</h2>
      <IonGrid>
        <IonRow className="ion-justify-content-center">
          <IonCol size="12" sizeMd="10" sizeLg="8">
            <IonCard className="about-card">
              <IonCardContent className="card-content">
                <div className="icon-container">
                  <IonIcon icon={informationCircleOutline} className="info-icon" />
                </div>
                <div className="content-container">
                  <p className="about-card-p">{isFlipped ? fullContent : shortContent}</p>
                </div>
                <IonButton
                  className="flip-button"
                  fill="clear"
                  onClick={handleFlip}
                >
                  {isFlipped ? (
                    <>
                      Read Less
                    </>
                  ) : (
                    <>
                      Read More
                      
                    </>
                  )}
                </IonButton>
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AboutCard;