import React, { useRef, useState } from "react";
import axios from "axios";
import {
  IonCard,
  IonCardContent,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonTextarea,
  IonButton,
  IonImg,
  IonIcon,
  IonToast,
} from "@ionic/react";
import "./ContactForm.css";
import LogoWithWords from "../../assets/logos/arkwWords.png";
import { callOutline, mailOutline, sendOutline } from "ionicons/icons";

const ContactForm: React.FC = () => {
  const [copied, setCopied] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toastRef = useRef(null);
  const phoneNumber = "4193189839";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [contactForm, setContactForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleClick = () => {
    const emailAddress = "kyle@arkwd.com";
    window.open(`mailto:${emailAddress}`, "_blank");
  };

  const handlePhone = () => {
    if (isMobile) {
      window.location.href = `tel:${phoneNumber}`;
    } else {
      navigator.clipboard.writeText(phoneNumber)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 1000);
        })
        .catch((error) => console.error("Failed to copy phone number: ", error));
    }
  };

  const submitContact = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (!contactForm.firstname || !contactForm.lastname || !contactForm.email || !contactForm.subject || !contactForm.message) {
        console.error("Please fill out all required fields.");
        return;
      }

      const webhookUrl = "https://discord.com/api/webhooks/1115040802138243142/DEbe4ou5JQISbo_TAFiphlSDhqe_8pbEO1AHo2ZW5QQK8UL-DoYbmsEneddn3fzmoKg1";
      await axios.post(webhookUrl, {
        content: `New form submission:\n\nFirst Name: ${contactForm.firstname}\nLast Name: ${contactForm.lastname}\nEmail: ${contactForm.email}\nPhone: ${contactForm.phone}\nSubject: ${contactForm.subject}\nMessage: ${contactForm.message}`,
      });

      setContactForm({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
      });
      setSubmitted(true);
      setTimeout(() => setSubmitted(false), 1000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="contact-container">
      <h2 className="section-heading">Contact Us</h2>
      <IonCard className="contact-card">
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol size="12" sizeMd="6" className="contact-info">
                <IonImg src={LogoWithWords} className="form-logo" alt="Ark Web Development Logo" />
                <IonText>
                  <p>Get in touch with us! Fill out the form with your details, and we will be in touch soon.</p>
                </IonText>
                <div className="contact-buttons">
                  <IonButton fill="clear" onClick={handleClick} className="contact-action-button" mode="md">
                    <IonIcon icon={mailOutline} slot="start" />
                    Email us
                  </IonButton>
                  <IonButton fill="clear" onClick={handlePhone} className="contact-action-button" mode="md">
                    <IonIcon icon={callOutline} slot="start" />
                    Call us
                  </IonButton>
                </div>
              </IonCol>
              <IonCol size="12" sizeMd="6">
                <form onSubmit={submitContact} className="contact-form">
                  <IonInput
                    value={contactForm.firstname}
                    onIonChange={(e) => setContactForm({ ...contactForm, firstname: e.detail.value as string })}
                    className="contact-form-input"
                    label="First Name"
                    labelPlacement="floating"
                    fill="outline"
                    placeholder="John"
                    required={true}
                    mode="md"
                  />
                  <IonInput
                    value={contactForm.lastname}
                    onIonChange={(e) => setContactForm({ ...contactForm, lastname: e.detail.value as string })}
                    className="contact-form-input"
                    label="Last Name"
                    labelPlacement="floating"
                    fill="outline"
                    placeholder="Doe"
                    required={true}
                    mode="md"
                  />
                  <IonInput
                    value={contactForm.email}
                    onIonChange={(e) => setContactForm({ ...contactForm, email: e.detail.value as string })}
                    className="contact-form-input"
                    label="Email"
                    labelPlacement="floating"
                    fill="outline"
                    type="email"
                    placeholder="johndoe@email.com"
                    required={true}
                    mode="md"
                  />
                  <IonInput
                    value={contactForm.phone}
                    onIonChange={(e) => setContactForm({ ...contactForm, phone: e.detail.value as string })}
                    className="contact-form-input"
                    label="Phone"
                    labelPlacement="floating"
                    fill="outline"
                    type="tel"
                    placeholder="123-456-7890"
                    mode="md"
                  />
                  <IonInput
                    value={contactForm.subject}
                    onIonChange={(e) => setContactForm({ ...contactForm, subject: e.detail.value as string })}
                    className="contact-form-input"
                    label="Subject"
                    labelPlacement="floating"
                    fill="outline"
                    placeholder="Make me a super cool website!"
                    required={true}
                    mode="md"
                  />
                  <IonTextarea
                    value={contactForm.message}
                    onIonChange={(e) => setContactForm({ ...contactForm, message: e.detail.value as string })}
                    className="contact-form-input"
                    label="Message"
                    labelPlacement="floating"
                    fill="outline"
                    placeholder="I want a cool new website that..."
                    required={true}
                    autoGrow={true}
                    rows={4}
                    mode="md"
                  />
                  <IonButton expand="block" type="submit" className="submit-button" mode="md">
                    Submit
                    <IonIcon icon={sendOutline} slot="end" />
                  </IonButton>
                </form>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonCardContent>
      </IonCard>
      <IonToast
        ref={toastRef}
        isOpen={copied}
        message="Phone number copied to clipboard"
        duration={1000}
      />
      <IonToast
        ref={toastRef}
        isOpen={submitted}
        message="Form received! Thank you!"
        duration={1000}
      />
    </div>
  );
};

export default ContactForm;