import React, { useState } from "react";
import {
  IonButton,
  IonCardContent,
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  IonCard,
} from "@ionic/react";
import {
  code,
  search,
  construct,
  card,
  analytics,
  earth,
  arrowForward,
  arrowBack,
} from "ionicons/icons";
import "./Services.css";

const Services: React.FC = () => {
  const [cardData, setCardData] = useState([
    {
      title: "Custom Web Development",
      icon: code,
      description: "We are your trusted partner, guiding you from ideation to deployment, to create captivating and responsive websites. Our expert team specializes in crafting user-friendly websites that engage visitors and elevate your online presence. Experience the power of our Progressive Web Applications (PWAs), ensuring flawless performance across various devices and platforms.",
      isFlipped: false,
    },
    {
      title: "Search Engine Optimization",
      icon: search,
      description: "Unlock the true potential of your website with our data-driven approach to SEO. Our in-depth knowledge of search engine algorithms and industry best practices enables us to optimize your website's structure, content, and performance strategically. From meticulous keyword research to implementing on-page and off-page optimization techniques, we leave no stone unturned to improve your search engine rankings.",
      isFlipped: false,
    },
    {
      title: "Hosting and Maintenance",
      icon: construct,
      description: "Focus on your core business while we handle the technical intricacies. Our comprehensive hosting and maintenance solutions ensure seamless website operation and continuous optimization. With reliable hosting and regular maintenance, you can provide your visitors with a smooth online experience, knowing that we have your website covered.",
      isFlipped: false,
    },
    {
      title: "Point-Of-Sales Integrations",
      icon: card,
      description: "Revolutionize your restaurant operations with our seamless POS integrations. By connecting your online ordering platform, kitchen operations, and payment processing, we enhance efficiency and elevate the dining experience. Our integrations streamline order management, reduce errors, and boost customer satisfaction, whether you own a single restaurant or a chain of establishments.",
      isFlipped: false,
    },
    {
      title: "Analytics",
      icon: analytics,
      description: "Unlock valuable insights about your website's performance with our comprehensive analytics services. Dive deep into user journeys, popular content, and conversion paths. Armed with this knowledge, we help you identify areas for improvement, optimize navigation and layout, and enhance the user experience to drive maximum engagement and conversions.",
      isFlipped: false,
    },
    {
      title: "Search Engine Marketing",
      icon: earth,
      description: "Gain a decisive edge over your competitors as we catapult your brand to the top of search engine results, driving targeted traffic, and maximizing your online visibility.",
      isFlipped: false,
    },
  ]);

  const handleFlip = (index: number) => {
    setCardData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], isFlipped: !newData[index].isFlipped };
      return newData;
    });
  };

  return (
    <div className="services-section">
      <h2 className="section-heading">Our Services</h2>
      <IonGrid>
        <IonRow>
          {cardData.map((card, index) => (
            <IonCol key={index} size="12" sizeMd="6" sizeLg="4">
              <IonCard className={`service-card ${card.isFlipped ? 'flipped' : ''}`}>
                <div className="card-inner">
                  <div className="card-front">
                    <IonCardContent className="card-content">
                      <IonIcon className="card-icon" icon={card.icon} />
                      <h3 className="card-title">{card.title}</h3>
                      <IonButton
                        fill="clear"
                        className="flip-button"
                        onClick={() => handleFlip(index)}
                      >
                        Learn More
                        <IonIcon slot="end" icon={arrowForward} />
                      </IonButton>
                    </IonCardContent>
                  </div>
                  <div className="card-back">
                    <IonCardContent className="card-content">
                      <p className="card-description">{card.description}</p>
                      <IonButton
                        fill="clear"
                        className="flip-button"
                        onClick={() => handleFlip(index)}
                      >
                        <IonIcon slot="start" icon={arrowBack} />
                        Back
                      </IonButton>
                    </IonCardContent>
                  </div>
                </div>
              </IonCard>
            </IonCol>
          ))}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default Services;